<template>
  <div class="card">
    <Headline :level="2" :size="5">Fragrance Notes</Headline>
    <NoteProfile :notes="fragranceNotes" class="fragrance-notes" />
  </div>
  <div class="card">
    <Headline :level="2" :size="5">Details</Headline>
    <PropertyTable :properties="fragranceProperties" />
  </div>
  <div class="card">
    <Headline :level="2" :size="5">Recommended Applications</Headline>
    <PropertyTable :properties="fragranceRecommendedApplications" />
    <Hint>
      Usage amounts are recommendations only. Individual usage amounts can vary
      based on your desired fragrance strength, wax type, soap base, or lotion
      base. See IFRA certificate for max usage levels. Bottles filled by weight,
      levels may vary.
    </Hint>
    <Hint v-if="fragranceData.containsProp65Ingredients">
      **Warning: {{ fragranceData.prop65Warning }}
    </Hint>
  </div>
  <div v-if="soapPerformance" class="card">
    <Headline :size="5" :level="2">Cold Process Soap Performance</Headline>
    <SoapPerformance
      :acceleration="soapPerformance.acceleration"
      :discoloration="soapPerformance.discoloration"
      :separation="soapPerformance.separation"
    />
    <Hint
      link-label="Read more about our process"
      link-href="/fragrance-oil/process-for-cp-soap-fragrance-testing/"
    >
      {{ soapPerformance.sanitized_body }}
    </Hint>
  </div>
</template>

<script>
  import Headline from "~/components/Headline"
  import Hint from "~/components/Hint"
  import NoteProfile from "~/components/NoteProfile"
  import SoapPerformance from "~/components/SoapPerformance"
  import PropertyTable from "~/components/PropertyTable"

  export default {
    components: {
      Headline,
      PropertyTable,
      Hint,
      NoteProfile,
      SoapPerformance,
    },
    props: {
      fragranceData: { type: Object, required: true },
    },
    data() {
      const fragranceComponents = this.fragranceData.components

      const filterNotesByPosition = () => {
        return fragranceComponents.reduce(
          (acc, note) => {
            const { position, ...rest } = note
            acc[position.toLowerCase()].push(rest)
            return acc
          },
          { top: [], middle: [], base: [] },
        )
      }

      const fragranceDetails = this.fragranceData.infos
      const filterFragranceDetails = () => {
        const excludedLabels = new Set([
          "Candle Safe",
          "Lotion Safe",
          "Soap Safe",
          "Diffuser Base Approved",
        ])
        return fragranceDetails.filter(
          (detail) => !excludedLabels.has(detail.label),
        )
      }

      return {
        fragranceProperties: filterFragranceDetails(),
        fragranceRecommendedApplications:
          this.fragranceData?.recommendedApplications,
        fragranceNotes: filterNotesByPosition(),
        soapPerformance: this.fragranceData.coldProcessDisclaimer,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .card {
    @include card;
  }
</style>
