import type FragranceChoice from "~/components/FragranceChoice.vue"

export const addKitItemsToVariant = async (
  selectedVariant: SolidusProductVariant | ProductVariantWithKitItems,
  fragranceChoice: InstanceType<typeof FragranceChoice>,
): Promise<ProductVariantWithKitItems> => {
  // Check if Component reference is available
  try {
    // Get Kit Configuration
    const { kitConfiguration } = selectedVariant

    if (!kitConfiguration) {
      console.error("no kitConfiguration found")
      return selectedVariant
    }

    const kitItems = await fragranceChoice.getFragrances({
      type_code: kitConfiguration.variantTypeCode,
      quantity: kitConfiguration.quantity,
      predefinedFacets: kitConfiguration.algoliaScopes,
      title: "Choose Diffuser Base Fragrances",
      selectedProducts:
        "kitItems" in selectedVariant ? selectedVariant.kitItems : [],
    })

    // Updated Variant with kit Items
    return {
      ...selectedVariant,
      kitItems,
    }
  } catch {
    // this is the case of an aborted fragrance selection process.
    return selectedVariant
  }
}
