<template>
  <div id="reviews" class="reviews">
    <div class="reviews-intro">
      <Headline :size="4" :level="2" class="reviews-headline">
        Product Reviews
      </Headline>
      <Button
        size="small"
        color="yellow"
        :to="loggedIn ? `/reviews/new/${productId}/` : '/login/'"
        :prefetch="false"
        rel="nofollow"
      >
        Write A Review
      </Button>
    </div>
    <Rating :rating="rating" :review-count="reviewCount" />
    <span class="header-review-count">
      {{ reviewCount }}
      {{ reviewCount !== 1 ? "reviews" : "review" }}
    </span>
    <div v-if="reviewCount">
      <!-- eslint-disable vue/no-v-html -->
      <ReviewsDisplay
        :helpful-reviews="helpfulReviews"
        :top-reviews="topReviews"
        :additional-reviews-available="additionalReviewsAvailable"
        :product-id="productId"
      />
    </div>
    <div v-else class="product-reviews-empty-state">
      {{ productName }} has no Reviews yet, be the first to write one!
    </div>
  </div>
</template>

<script>
  import ReviewsDisplay from "./ReviewsDisplay"

  export default {
    components: {
      ReviewsDisplay,
    },
    props: {
      productId: {
        type: String,
        required: true,
      },
      topReviews: {
        type: Array,
        required: true,
        default: () => [],
      },
      helpfulReviews: {
        type: Array,
        required: true,
        default: () => [],
      },
      additionalReviewsAvailable: {
        type: Boolean,
        required: true,
        default: false,
      },
      reviewCount: {
        type: Number,
        required: true,
        default: 0,
      },
      productName: {
        type: String,
        required: true,
      },
      rating: {
        type: Number,
        default: 3,
      },
    },
    setup() {
      const authStore = useAuthStore()
      const loggedIn = computed(() => {
        return authStore.loggedIn
      })

      return {
        loggedIn,
      }
    },
  }
</script>

<style lang="scss">
  .review-body,
  .comment-text {
    p {
      @apply text-sm leading-normal;
      margin: 0;
      @extend %dont-break-out;
    }
  }
</style>

<style lang="scss" scoped>
  .reviews-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .review-section {
    margin-top: $gutter;
  }

  .header-review-count {
    font-weight: bold;
    color: $yellow-text;
    position: relative;
    text-decoration: none;
    padding-left: $base-spacing * 2;
    transform: translateY(1px);
  }

  .load-more-reviews {
    padding-top: $base-spacing * 4;
  }
</style>
