<template>
  <div class="card">
    <Headline :size="5">Instructions</Headline>
    <Paragraph :size="'small'">{{ instruction.body }}</Paragraph>
  </div>
</template>

<script>
  import Headline from "~/components/Headline"
  import Paragraph from "~/components/Paragraph"

  export default {
    components: { Headline, Paragraph },
    props: {
      instruction: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .card {
    @include card;
  }
</style>
