<template>
  <div class="add-to-list-wrap">
    <button
      class="add-to-list-toggle"
      :class="show && 'add-to-list-toggle-open'"
      size="small"
      @click="show = !show"
    >
      Add to List
      <SvgIcon icon="chevron-down" class="add-to-list-toggle-icon" />
    </button>

    <div v-if="show" class="list-index">
      <button
        v-for="list in recipes"
        :key="list.id"
        :disabled="loading"
        class="list-index-button"
        @click="addProduct(list.id)"
      >
        {{ list.title }}
      </button>

      <transition
        v-if="loading || successful"
        tag="div"
        class="overlay"
        name="add-to-recipe-anim"
      >
        <div v-if="successful" key="success-box" class="success-box">
          <SvgIcon icon="form-check" class="check-icon" role="none" />
          {{ successfulMessage }}
        </div>
        <Spinner v-else key="spinner" :spin="loading" />
      </transition>
      <button class="list-index-new-list" @click="toNewList">
        Add to a new List
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  // TODO Add Error Handling / Alerts
  import { deserialize } from "@alchemy_cms/json_api"

  const props = defineProps<{
    product: SolidusProduct
    variantId: string
  }>()

  const show = ref(false)
  const loading = ref(false)
  const successful = ref(false)
  const successfulMessage = ref("")

  const { api } = useApi()

  const { handleError } = useErrorHandling()
  const { data: recipes } = useLazyAsyncData(
    async () => {
      try {
        const data = await api("/jsonapi/user_recipes")
        return deserialize(data)
      } catch (error) {
        handleError(error)
      }
    },
    {
      server: false,
    },
  )

  const setSuccessful = (messsage: string) => {
    successful.value = true
    successfulMessage.value = messsage
    setTimeout(() => {
      successful.value = false
      successfulMessage.value = ""
      show.value = false
    }, 2500)
  }

  const addProduct = async (recipeId: string) => {
    loading.value = true
    try {
      const data = await api("/jsonapi/user_recipe_items", {
        method: "POST",
        body: {
          data: {
            attributes: {
              recipeId,
              item_attributes: {
                variant_id: props.variantId,
                quantity: 1,
              },
            },
          },
        },
      })
      recipes.value = deserialize(data)
      setSuccessful("Added to List")
    } catch (error) {
      handleError(error)
    } finally {
      loading.value = false
    }
  }

  const toNewList = async () => {
    loading.value = true
    try {
      const data = await api("/jsonapi/user_recipes", {
        method: "POST",
        body: {
          data: {
            attributes: {
              title: `New List with ${props.product.name}`,
              items_attributes: [
                {
                  quantity: 1,
                  variant_id: props.variantId,
                },
              ],
            },
          },
        },
      })
      const newArray = Array.from(recipes.value)
      newArray.push(deserialize(data))
      recipes.value = newArray

      setSuccessful("List Created")
    } catch (error) {
      handleError(error)
    } finally {
      loading.value = false
    }
  }
</script>

<style scoped lang="scss">
  .add-to-list-wrap {
    position: relative;
    width: 100%;
    margin-bottom: $space-s;
  }
  .add-to-list-toggle {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @apply text-sm leading-normal;
    border: 1px solid $gray-300;
    border-radius: $border-radius;
    color: $onyx;
    padding: $base-spacing * 2 $base-spacing * 3;
    width: 100%;
    background: white;

    color: $onyx;
    &-open {
      border-bottom-color: white;
      box-shadow: $shadow;
      border-radius: $border-radius $border-radius 0 0;
    }
  }

  .list-index {
    grid-gap: $base-spacing * 2;
    padding: $base-spacing * 2 0;
    z-index: 50;
    box-sizing: border-box;
    border: 1px solid $gray-200;
    border-top-color: white;
    box-shadow: $shadow;
    width: 100%;
    top: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    background: white;
    max-height: 50vh;
    overflow-y: auto;
  }

  .list-index-button {
    text-align: left;

    padding: $base-spacing * 1 $base-spacing * 3;
    @apply text-sm leading-normal;
    border: none;
    background: none;
    color: $onyx;
    &:hover {
      background: $gray-100;
    }
  }
  .list-index-new-list {
    border-radius: $border-radius;
    margin: 0 auto;
    @apply h6;
    background: white;
    border: 1px solid $green;
    padding: $base-spacing * 2 $base-spacing * 3;
    width: calc(100% - $base-spacing * 4);
    color: $green-text;
  }
  .add-to-list-toggle-icon {
    color: $gray-shape;
    width: $base-spacing * 6;
    height: $base-spacing * 6;
    transition: transform 0.2s ease-in-out;
    .add-to-list-toggle-open & {
      transform: rotate(180deg);
    }
  }

  .overlay {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.95);
    align-items: center;
    justify-content: center;
  }
  .success-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .check-icon {
    color: $green-shape;
    width: $base-spacing * 6;
    height: $base-spacing * 6;
    margin-right: $base-spacing * 2;
  }

  .add-to-recipe-anim-enter-active,
  .add-to-recipe-anim-leave-active {
    transition:
      opacity 280ms ease,
      transform 280ms ease;
  }

  .add-to-recipe-anim-enter {
    opacity: 0;
    transform: translateY(8px) scale(0.95);
  }
  .add-to-recipe-anim-leave-to {
    opacity: 0;
    transform: translateY(8px) scale(0.95);
  }
</style>
