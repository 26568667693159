<template>
  <div class="mb-4">
    <nuxt-link
      to="/candlescience-cleanscents-program-overview/"
      class="product-clean-scents"
    >
      <CleanScents icon="text" :level="solidCleanScentLevel" />
    </nuxt-link>

    <template v-if="cleanScentNote && cleanScentLevel === 'clean_scent'">
      <button
        class="border-0 appearance-none bg-transparent p-0 font-sans text-gray-text text-sm mt-2"
        @click="cleanScentsModal?.openModal()"
        ref="ingredient-note-button"
      >
        Ingredient notes ›
      </button>

      <CsModal
        ref="cleanScentsModal"
        :close-on-backdrop-click="true"
        @close="cleanScentsModal?.close()"
      >
        <div class="relative flex items-center justify-between p-6 pl-8">
          <h4 class="h4 my-0">Ingredient notes</h4>
          <button
            aria-label="Close"
            class="bg-gray-100 border-none w-11 h-11 rounded-full p-2.5"
            @click="cleanScentsModal?.close()"
          >
            <SvgIcon icon="x" class="w-6 h-6 block" />
          </button>
        </div>
        <div class="p-8 pt-0">
          <p class="my-0 text-md mb-8">{{ cleanScentNote }}</p>
          <Button
            size="medium"
            to="/candlescience-cleanscents-ingredient-information/"
          >
            Learn more
          </Button>
        </div>
      </CsModal>
    </template>
  </div>

  <div class="product-eo-badges" v-if="isEo">
    <span v-if="groundOnly" class="eo-badge">
      <img
        src="/images/badges/ground-shipping.svg"
        alt="Badge for Ground Shipping"
      />
      Ground <br />
      Shipping
    </span>
    <span class="eo-badge">
      <img src="/images/badges/solvent-free.svg" alt="Badge for Solvent Free" />
      Solvent <br />
      Free
    </span>
    <span
      v-for="badge in essentialOilBadges"
      :key="badge.name"
      class="eo-badge"
    >
      <img
        :src="`/images/badges/${NAME_IMAGE_MAP[badge.name]}`"
        :alt="`Badge for ${ALT_IMAGE_MAP[badge.name]}`"
      />
      <template v-if="badge.name === 'certified_origin'">
        Certified <br />Origin
      </template>
      <template v-else-if="badge.name === 'sustainable_practices'">
        Sustainable <br />Practices
      </template>
      <template v-else-if="badge.name === 'non_gmo_product'">
        Non-GMO <br />Product
      </template>
      <template v-else-if="badge.name === 'cruelty_free'">
        Cruelty <br />Free
      </template>
      <template v-else-if="badge.name === 'vegan_product'">
        Vegan <br />
        Product
      </template>
      <template v-else-if="badge.name === 'upcycled_sourcing'">
        Upcycled <br />
        Sourcing
      </template>
    </span>
  </div>
</template>

<script setup lang="ts">
  import CsModal from "@/components/CsModal.vue"

  const cleanScentsModal = ref<InstanceType<typeof CsModal> | null>(null)

  const NAME_IMAGE_MAP = {
    certified_origin: "certified-origin.svg",
    certified_source: "certified-sourcing.svg",
    cruelty_free: "cruelty-free.svg",
    non_gmo_product: "non-gmo-product.svg",
    sustainable_practices: "sustainable-practices.svg",
    upcycled_sourcing: "upcycled-sourcing.svg",
    vegan_product: "vegan-product.svg",
  }

  const ALT_IMAGE_MAP = {
    certified_origin: "Certified Origin",
    certified_source: "Certified Sourcing",
    cruelty_free: "Cruelty Free",
    non_gmo_product: "Non-GMO Product",
    sustainable_practices: "Sustainable Practices",
    upcycled_sourcing: "Upcycled Sourcing",
    vegan_product: "Vegan Product",
  }

  const props = defineProps<{
    cleanScentNote?: CsFragrance["cleanScentNote"]
    cleanScentLevel?: CsFragrance["cleanScentLevel"]
    groundOnly: boolean
    essentialOilBadges?: {
      name: keyof typeof NAME_IMAGE_MAP
    }[]
    isEo: boolean
  }>()

  const solidCleanScentLevel = computed(() =>
    props.cleanScentLevel ? props.cleanScentLevel : "not_clean",
  )
</script>

<style scoped lang="scss">
  .product-eo-badges {
    color: $gray-text;
    display: flex;
    grid-gap: $base-spacing * 4;
    line-height: 1;
    font-family: $font-family-headline;
    justify-content: flex-start;
    padding-bottom: $base-spacing * 2;
    margin-bottom: $base-spacing * 2;
    flex-flow: row wrap;
    @include viewport("m") {
    }
  }

  .product-clean-scents {
    display: block;
    p {
      margin: $base-spacing * 2 0 0;
    }
  }

  .eo-badge {
    flex-shrink: 0;
    box-sizing: border-box;
    color: $gray-text;

    display: inline-flex;
    align-items: center;
    font-weight: bold;
    grid-gap: $base-spacing * 2;
    white-space: nowrap;
    img {
      width: $base-spacing * 8;
      height: $base-spacing * 8;
    }
  }
</style>
