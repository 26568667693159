<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="reviews-wrap">
    <div v-if="!additionalReviewsAvailable" class="review-section">
      <Headline :size="5" :level="3" class="reviews-headline">
        All Reviews
      </Headline>
      <ProductReviewsReview
        v-for="review in filterOptions['allReviews'].reviews"
        :key="review.id"
        :review="review"
        :loading="pending"
        @helpful="upVoteReview"
      />
    </div>
    <div v-else>
      <div class="review-section">
        <label class="reviews-filter-select_wrap">
          <span class="reviews-filter-select_wrap-label"> Displaying: </span>
          <Select
            :model-value="filterSelection"
            class="reviews-filter-select"
            size="small"
            name="filter"
            @update:model-value="changeFilterSelection"
          >
            <option
              v-for="key in Object.keys(filterOptions)"
              :key="key"
              :value="key"
            >
              {{ filterOptions[key].displayName }}
            </option>
          </Select>
        </label>
        <ProductReviewsReview
          v-for="review in filterOptions[filterSelection].reviews"
          :key="review.id"
          :review="review"
          :loading="pending"
          @helpful="upVoteReview"
        />
        <Button
          v-if="filterSelection === 'allReviews' && loadMoreReviews"
          :loading="pending"
          color="gray"
          size="medium"
          layout="ghost"
          @click="fetchReviews"
        >
          {{ fetchButtonLabel }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { deserialize } from "@alchemy_cms/json_api"

  const props = defineProps({
    additionalReviewsAvailable: {
      type: Boolean,
      required: true,
      default: false,
    },
    topReviews: {
      type: Array,
      required: true,
      default: () => [],
    },
    helpfulReviews: {
      type: Array,
      required: true,
      default: () => [],
    },
    productId: {
      type: String,
      required: true,
    },
  })

  const filterSelection = ref("topReviews")
  const filterOptions = ref({
    topReviews: {
      displayName: "Highlighted Reviews",
      reviews: props.topReviews.map((a) => ({ ...a })),
    },
    helpfulReviews: {
      displayName: "Top Helpful Reviews",
      reviews: props.helpfulReviews.map((a) => ({ ...a })),
    },
    allReviews: {
      displayName: "All Reviews",
      reviews: [],
    },
  })
  const page = ref(1)
  const { api } = useApi()
  const authStore = useAuthStore()
  const router = useRouter()
  const loadMoreReviews = ref(props.additionalReviewsAvailable)
  const { handleError } = useErrorHandling()
  const pending = ref(false)

  const fetchButtonLabel = computed(() =>
    page.value === 1 ? "Show Reviews" : "Show More",
  )

  const changeFilterSelection = (newSelection) => {
    filterSelection.value = newSelection
  }

  const hasUpVoted = (id) => {
    return authStore.user?.up_voted_review_ids?.includes(id)
  }

  const loggedIn = computed(() => {
    return authStore.loggedIn
  })
  const upVoteReview = async (id) => {
    if (!loggedIn.value) {
      await router.push("/login/")
      return
    }

    pending.value = true

    const updateReviewCount = (id, delta) => {
      Object.values(filterOptions.value).forEach((option) => {
        option.reviews.forEach((review) => {
          if (review.id === id) {
            review.upVoteCount += delta
          }
        })
      })
    }

    const toggleUpvote = async (method, url, successCallback) => {
      try {
        await api(url, {
          method,
          body: {
            up_vote: {
              user_id: Number(authStore.user?.id),
              review_id: Number(id),
            },
          },
        })
        successCallback()
      } catch (error) {
        if (error.status === 404 && method === "DELETE") {
          authStore.user.up_voted_review_ids =
            authStore.user?.up_voted_review_ids.filter((item) => item !== id)
        }
      } finally {
        pending.value = false
      }
    }

    if (hasUpVoted(id)) {
      await toggleUpvote("DELETE", `/jsonapi/up_votes/${id}`, () => {
        updateReviewCount(id, -1)
        authStore.user.up_voted_review_ids =
          authStore.user?.up_voted_review_ids.filter(
            (element) => element !== id,
          )
      })
    } else {
      await toggleUpvote("POST", "/jsonapi/up_votes", () => {
        updateReviewCount(id, 1)
        if (!hasUpVoted(id)) {
          authStore.user?.up_voted_review_ids.push(id)
        }
      })
    }
  }

  const refreshReviews = async () => {
    const neededReviewIds = []
    Object.values(filterOptions.value).forEach((option) => {
      option.reviews.forEach((review) => {
        if (!neededReviewIds.includes(review.id)) {
          neededReviewIds.push(review.id)
        }
      })
    })

    const data = await api(`/jsonapi/products/${props.productId}/reviews`, {
      params: {
        include: "response",
        filter: { id_in: neededReviewIds },
        // page: {
        //   size: neededReviewIds.length,
        // },
      },
    })
    const freshReviews = deserialize(data)
    freshReviews.forEach((review) => {
      Object.values(filterOptions.value).forEach((option) => {
        option.reviews.forEach((storedReview) => {
          if (review.id === storedReview.id) {
            storedReview.upVoteCount = review.upVoteCount
          }
        })
      })
    })
  }

  const fetchReviews = async () => {
    try {
      pending.value = true
      const data = await api(`/jsonapi/products/${props.productId}/reviews`, {
        params: {
          include: "response",
          page: {
            number: page.value,
            size: 10,
          },
        },
      })
      if (page.value === 1) {
        filterOptions.value.allReviews.reviews = deserialize(data)
      } else {
        filterOptions.value.allReviews.reviews.push(...deserialize(data))
      }
      loadMoreReviews.value = page.value < data.meta?.pagination?.last
      page.value++
    } catch (error) {
      await handleError(error)
    } finally {
      pending.value = false
    }
  }

  onMounted(() => {
    refreshReviews()
    fetchReviews()
  })
</script>

<style lang="scss" scoped>
  .review {
    @apply text-sm leading-normal;

    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: $gap 0;
    border-bottom: 1px solid var(--border);
    grid-gap: calc($space-s / 2);
    justify-content: space-between;
  }
  .reviews-filter-select_wrap {
    display: flex;
    align-items: center;
    flex-grow: 0;
    justify-self: flex-end;
    grid-gap: $base-spacing * 2;
    &-label {
      font-weight: bold;
      white-space: nowrap;
    }
    @include viewport("mini") {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .reviews-filter-select {
    max-width: 200px;
    @include viewport("mini") {
      max-width: none;
    }
  }
</style>
