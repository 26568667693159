const createProductMeta = (
  product: SolidusProduct,
  image: string,
  baseurl: string,
) => {
  const fullUrl = `${baseurl}${product.urlPath}`

  return {
    title: product.metaTitle,
    link: [
      {
        rel: "canonical",
        href: fullUrl,
      },
    ],
    meta: [
      {
        name: "description",
        content: product.metaDescription,
      },
      {
        name: "og:description",
        content: product.metaDescription,
      },
      {
        name: "keywords",
        content: product.metaKeywords,
      },
      {
        property: "og:image",
        content: image,
      },
      {
        property: "og:type",
        content: "product",
      },
      {
        property: "og:title",
        content: product.metaTitle,
      },
      {
        property: "og:image:width",
        content: "750",
      },
      {
        property: "og:image:height",
        content: "750",
      },
      {
        property: "og:image",
        content: image,
      },
      {
        property: "og:url",
        content: fullUrl,
      },
      {
        property: "twitter:title",
        content: product.metaTitle,
      },
      {
        property: "twitter:image",
        content: image,
      },
      {
        property: "twitter:url",
        content: fullUrl,
      },
      {
        property: "twitter:card",
        content: "summary_large_image",
      },
      {
        property: "twitter:description",
        content: product.metaDescription,
      },
    ],
  }
}

export default createProductMeta
