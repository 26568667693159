<template>
  <DocumentLink :type="dlType" :href="href">{{ name }}</DocumentLink>
</template>

<script>
  import DocumentLink from "~/components/DocumentLink"

  export default {
    components: { DocumentLink },
    props: {
      href: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
    },
    data() {
      let name
      let dlType
      switch (this.type) {
        case "IFRA":
          name = "IFRA Certificate"
          dlType = "ifra"
          break
        case "MSDS":
          name = "Material Safety Data Sheet"
          dlType = "sheet"
          break
        case "SDS":
          name = "Safety Data Sheet"
          dlType = "sheet"
          break
        case "invoicePdf":
          name = "Download PDF Invoice"
          dlType = "pdf"
          break
        default:
          name = this.type
          dlType = "doc"
          break
      }
      return {
        name,
        dlType,
      }
    },
  }
</script>
