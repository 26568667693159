<template>
  <div class="review">
    <div
      class="flex gap-2 flex-1 basis-full mini:basis-0 items-center flex-wrap"
    >
      <Rating :rating="review.rating" />
      <span v-if="review.title" class="title">{{ review.title }}</span>
    </div>
    <div>
      <span class="author">{{ review.author }}</span>
      <span class="date">
        {{ review.localizedDate }}
      </span>
    </div>
    <template v-if="review.sanitizedReview">
      <!--  eslint-disable-next-line vue/no-v-html -->
      <div class="review-body" v-html="review.sanitizedReview" />
    </template>
    <Button
      color="blue"
      size="small"
      :loading="loading"
      :layout="hasUpVoted ? 'tint' : 'inverted'"
      @click="$emit('helpful', review.id)"
    >
      <template #icon>
        <SvgIcon icon="thumbs-up" class="thumbs-up-icon" />
      </template>
      {{ hasUpVotes ? review.upVoteCount : "Helpful" }}
    </Button>
    <div v-if="review.response && review.response.sanitizedBody">
      <div class="comment">
        <img
          alt="CandleScience Profile Avatar"
          src="/images/cs-avatar.png"
          class="user-image"
          loading="lazy"
        />
        <div class="comment-body">
          <div class="comment-info">
            <span class="title">CandleScience responded: </span>
            <span class="date">
              {{ review.response.localizedDate }}
            </span>
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="comment-text" v-html="review.response.sanitizedBody" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  const props = defineProps({
    review: {
      type: Object,
      required: true,
    },
    loading: {
      required: true,
      type: Boolean,
      default: false,
    },
  })
  const authStore = useAuthStore()
  const hasUpVotes = computed(() => {
    return props.review.upVoteCount > 0
  })
  const hasUpVoted = computed(() => {
    return (
      authStore.user?.up_voted_review_ids?.includes(props.review.id) || false
    )
  })
</script>
<style lang="scss" scoped>
  .title {
    font-weight: bold;
  }
  .date {
    color: $gray-text;
  }
  .author {
    color: $gray-text;
  }
  .review-head {
    align-items: center;
    padding-bottom: $base-spacing * 1.5;
  }
  .review-body {
    color: $gray-text;
  }
  .review-body,
  .review-footer {
    width: 100%;
  }
  .review-footer {
    @apply text-xs leading-normal;
    text-align: right;
  }
  .thumbs-up-icon {
    width: $base-spacing * 5;
    height: $base-spacing * 5;
  }
  .comment {
    grid-gap: $space-s;
    padding-top: $base-spacing * 4;
    display: flex;

    &-info {
      display: flex;
      justify-content: space-between;
      @include viewport("mini") {
        justify-content: initial;
        flex-direction: column;
      }
    }
  }
  .comment-text {
    margin: $base-spacing 0;
    margin: 0;
    @apply text-sm leading-normal;
    color: $gray-700;
  }
  .user-image {
    margin-top: $base-spacing * 2;
    width: $base-spacing * 9;
    height: $base-spacing * 9;
    border-radius: $border-radius;
  }
</style>
