<template>
  <div class="product-detail-page">
    <!-- PDP Header (Image, Prices, Cleanscents) -->
    <Container>
      <div v-if="product.retired" class="product-replaced-notice">
        <Alert
          :title="`As of ${discontinueOnDate}`"
          text-block
          type="warning"
          align="left"
        >
          <template #icon>
            <SvgIcon icon="info" />
          </template>
          {{ product.retiredMessage }}
          <template #action>
            <nuxt-link
              v-if="product.replacementProduct"
              :to="product.replacementProduct.urlPath"
            >
              See new version
            </nuxt-link>
          </template>
        </Alert>
      </div>

      <div class="product-detail-content">
        <ProductImages
          class="product-images"
          :images="images || []"
          :labels="labels"
          :discontinued="product.discontinued"
          :has-replacement="
            Object.keys(product.replacementProduct || {})?.length > 0
          "
          :is-blending-element="isBlendingElement"
        />

        <div class="product-mobile-detail">
          <Headline no-margin :size="2" class="product-headline">
            {{ product.name }}
          </Headline>
          <div v-if="productSubtitle" class="product-sub-headline">
            {{ productSubtitle }}
          </div>

          <a v-if="product.reviewCount" class="header-reviews" href="#reviews">
            <p class="at-text">
              {{ product.name }} has a {{ product.stars }}
              of 5 Stars Rating
            </p>
            <Rating
              :rating="product.stars"
              :review-count="product.reviewCount"
            />
            <span class="header-reviews-label">
              {{ product.reviewCount }} reviews
            </span>
          </a>
        </div>

        <div class="product-infos">
          <div class="product-name_wrap">
            <Headline :size="2" class="product-headline" no-margin>
              {{ product.name }}
            </Headline>

            <div v-if="productSubtitle" class="product-sub-headline">
              {{ productSubtitle }}
            </div>

            <a
              v-if="product.reviewCount"
              class="header-reviews"
              href="#reviews"
            >
              <p class="at-text">
                {{ product.name }} has a {{ product.stars }}
                of 5 Stars Rating
              </p>
              <Rating
                :rating="product.stars"
                :review-count="product.reviewCount"
              />
              <span class="header-reviews-label">
                {{ product.reviewCount }}
                {{ product.reviewCount !== 1 ? "reviews" : "review" }}
              </span>
            </a>
            <a v-else href="#reviews"> Be the first to review this. </a>
          </div>
          <ProductCartForm
            v-if="!product.retired"
            :variants="refinedVariants"
            :is-fragrance="!!product.fragrance"
            :product-url="product.customUrl"
            :is-discontinued="product.discontinued"
            :disabled="allItemsOutOfStock"
            :loading="loading"
            v-model="selectedVariant"
            :show-fragrance-choose-button="showFragranceChooseButton"
            @update:model-value="onVariantSelected"
            @addToCart="pdpAddToCart"
            @getFragrances="getFragrances(selectedVariant)"
          />
        </div>
        <div class="product-extra">
          <AddToRecipe
            v-if="selectedVariant && userRecipeEnabled"
            :product="product"
            :variant-id="selectedVariant?.id"
          />

          <ProductBadges
            :clean-scent="cleanScent"
            :clean-scent-level="cleanScentLevel"
            :clean-scent-note="product.fragrance?.cleanScentNote"
            :ground-only="!product.shippableByAir"
            :is-eo="!!product.essentialOil"
            :essential-oil-badges="product.essentialOil?.badges"
          />

          <SocialIcons
            sharing
            pinterest
            facebook
            class="product-social-icons"
          />
        </div>
      </div>
    </Container>

    <Container v-if="product.productNotice">
      <InfoBox
        class="product-notice"
        align="left"
        :text="product.productNotice"
      />
    </Container>

    <Container
      v-if="product.relatedProducts && product.relatedProducts.length > 0"
      class="related-products"
    >
      <Headline
        :level="2"
        :size="3"
        class="related-products-headline"
        :no-margin="true"
      >
        You may also be interested in:
      </Headline>
      <ProductThumb
        v-for="relatedProduct in product.relatedProducts"
        :id="relatedProduct.id"
        :key="relatedProduct.id"
        layout="card"
        :src="relatedProduct.imageUrl"
        :srcset="imageSrcset(relatedProduct.imageSrcset, { maxWidth: 125 })"
        :alt="relatedProduct.imageAlt"
        :name="relatedProduct.name"
        :ga-category="relatedProduct.gaCategory"
        :rating="relatedProduct.stars"
        :price-range="relatedProduct.priceRange"
        :href="relatedProduct.urlPath"
      />
    </Container>

    <!-- PDP Content (Content, Details, Reviews, Documents) -->
    <div class="secondary">
      <Container>
        <div class="secondary-main">
          <div class="pdp-description">
            <WistiaEmbed
              v-if="product.wistiaVideoId"
              :id="product.wistiaVideoId"
            />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="text" v-html="product.description" />
          </div>

          <div class="pdp-information">
            <client-only>
              <Alert
                v-if="product.discontinued && !product.retired"
                class="discontinued-message"
                align="left"
                type="warning"
                :title="props.product.replacementProduct && 'Revision Notice'"
                text-block
              >
                <template #icon>
                  <SvgIcon icon="note" />
                </template>
                {{ product.discontinuedMessage }}
              </Alert>
            </client-only>
            <InfoBox
              v-if="!product.shippableByAir"
              title="This Product has Shipping Restrictions"
              type="warning"
              text="Due to the low flashpoint of this product it's not shippable by
            air."
            >
              <a :href="SUPPORT_LINK">
                Learn more about shipping restrictions
              </a>
            </InfoBox>

            <!-- Properties -->
            <FragranceInfo
              v-if="product.fragrance"
              :fragrance-data="product.fragrance"
            />
            <EssentialOilInfo
              v-if="product.essentialOil"
              :essential-oil-data="product.essentialOil"
            />
            <ProductProperties
              v-if="product.properties && product.properties.length"
              :properties="product.properties"
            />
            <ProductProperties
              v-if="product.candleContainer"
              headline="Container Properties"
              :properties="product.candleContainer.infos"
            />
            <template v-if="product.candleWax">
              <ProductProperties
                headline="Wax Properties"
                :properties="product.candleWax.infos"
              />
              <ProductProperties
                headline="Applications"
                :properties="product.candleWax.waxApplications"
              />
            </template>

            <ProductProperties
              v-if="product.candleWick"
              headline="Wick Properties"
              :properties="product.candleWick.infos"
            />

            <ProductInstruction
              v-if="product?.productInstruction?.body"
              :instruction="product.productInstruction"
            />

            <div class="document-links">
              <ProductDocumentLink
                v-for="document in product.documents"
                :key="document.url"
                :type="document.type"
                :href="document.url"
              />
            </div>
          </div>
          <div v-if="product.curalateProductId" class="product-curalate-wrap">
            <Headline :size="4" :level="3"> Community Inspiration </Headline>
            <div
              class="product-curalate"
              data-crl8-container-id="product"
              :data-crl8-filter="`productId:${product.curalateProductId}`"
            />
          </div>
          <ProductReviews
            :product-id="product.id"
            :top-reviews="product.topReviews"
            :helpful-reviews="product.helpfulReviews"
            :additional-reviews-available="product.additionalReviewsAvailable"
            :review-count="product.reviewCount"
            :product-name="product.name"
            :rating="product.stars"
          />
        </div>
      </Container>
    </div>
  </div>
  <ClientOnly>
    <FragranceChoice
      v-if="showFragranceChooseButton"
      ref="productVariantFragranceChoice"
    />
  </ClientOnly>
</template>

<script setup lang="ts">
  import { useGtm } from "@gtm-support/vue-gtm"
  import FragranceInfo from "../Product/FragranceInfo/index.vue"
  import EssentialOilInfo from "../Product/EssentialOilInfo/index.vue"
  import AddToRecipe from "./AddToRecipe/index.vue"
  import createProductMeta from "./createProductMeta"
  import ProductBadges from "./ProductBadges.vue"
  import trackProductView from "./trackProductView"

  import { addKitItemsToVariant } from "./utils/addKitItemsToVariant"
  import {
    getSelectableVariant,
    convertOptionsTextToQuery,
  } from "./utils/getSelectableVariant"

  import { variantImageMap } from "./utils/variantImageMap"
  import { imageSrcset } from "#imports"
  import FragranceChoice from "~/components/FragranceChoice.vue"
  import { useNotificationStore } from "~/pinia/notifications"
  import { createProductTags } from "~/lib/createProductTags.js"

  interface SelectionStore {
    [id: string]: ProductVariantWithKitItems
  }

  // Shipping Restriction Help Link
  const SUPPORT_LINK =
    "https://support.candlescience.com/hc/en-us/articles/201390030-What-does-the-fragrance-flashpoint-mean-"

  // Stores
  const notificationStore = useNotificationStore()
  const authStore = useAuthStore()
  const cartStore = useCartStore()

  // Stock Status
  const allItemsOutOfStock = computed(() => {
    return props.product.variants.every((v) => !v.inStock && !v.backorderable)
  })

  // Component Setup, Refs & Props

  const props = defineProps<{
    product: SolidusProduct
  }>()

  const { handleError } = useErrorHandling()
  const router = useRouter()
  const { $config } = useNuxtApp()
  const $csGtm = useGtm()
  const loading = ref(false)

  // Fragrance Choice
  const tmpKitSelectionStore: Ref<SelectionStore> = ref({})
  const productVariantFragranceChoice: Ref<InstanceType<
    typeof FragranceChoice
  > | null> = ref(null)

  // Feature Flag check
  const userRecipeEnabled = computed(
    () => authStore.user?.featureFlags?.userRecipes,
  )

  // Product Images & Labels
  const defaultImages = computed(() => [
    {
      alt: props.product.imageAlt,
      src: props.product.imageUrl,
      srcset: props.product.imageSrcset,
    },
  ])

  const variantImages: ProductPageImage[] = variantImageMap(
    props.product.variants,
  )

  const images = props.product.retired ? defaultImages.value : variantImages
  const image = images[0]?.src
  const labels = createProductTags(props.product.marketingTaxons)
  const cleanScent = computed(
    () => !!props.product.fragrance?.cleanScent || false,
  )
  const cleanScentLevel = computed(
    () => props.product.fragrance?.cleanScentLevel || "not_clean",
  )
  // We manually adjust the border radius of the product image when the image is blending element. We use the taxon id to determine that
  const BLENDING_ELEMENTS_TAXON_ID = "400"
  const isBlendingElement = !!props.product.taxons?.find(
    (taxon) => taxon.id === BLENDING_ELEMENTS_TAXON_ID,
  )
  const discontinueOnDate = props.product.discontinueOn
    ? new Date(props.product.discontinueOn).toLocaleDateString()
    : null

  // Opengraph & Meta tag generation
  useJsonld(props.product.schemaOrgJsonLd || null)
  useHead(createProductMeta(props.product, image, $config.public.BASE_URL))

  // Variant selection
  const selectedVariant: Ref<ProductVariantWithKitItems | undefined> = ref()

  const onVariantSelected = (e: ProductVariantWithKitItems) => {
    selectedVariant.value = e

    const VARIANTS_ID_MAP = props.product.variants.map((variant) => [
      variant.id,
      variant,
    ])
    const VARIANTS_OBJECT_MAP = Object.fromEntries(VARIANTS_ID_MAP)
    const selectedVariantObj = VARIANTS_OBJECT_MAP[e.id]

    router.replace({
      query: {
        variant: convertOptionsTextToQuery(selectedVariantObj.optionsText),
      },
    })
  }

  const pdpAddToCart = async (e: number) => {
    if (selectedVariant.value === undefined) {
      return
    }

    loading.value = true

    const kit_items = selectedVariant.value.kitItems?.map((item) => {
      return {
        variant_id: item.variantId,
        quantity: item.quantity || 1,
      }
    })

    const cartPayload: CartItem[] = [
      {
        variant_id: selectedVariant.value.id,
        kit_items,
        quantity: e,
      },
    ]

    try {
      await cartStore.addToCart(cartPayload)
    } catch (error) {
      handleError(error)
    } finally {
      loading.value = false
    }
  }

  // RefinedVariants
  const refinedVariants = computed<ProductVariantWithKitItems[]>(() => {
    const propVariants = props.product.variants

    return propVariants.map((variant) => {
      const tmpStoreItems = tmpKitSelectionStore.value[variant.id]?.kitItems

      if (variant.kitConfiguration && tmpStoreItems) {
        return {
          ...variant,
          kitItems: tmpStoreItems,
        }
      }

      return variant
    })
  })

  // First client visit setup
  onMounted(() => {
    notificationStore.announce(
      `Navigated to ${props.product.name} product page`,
    )

    const urlVariantQuery = router.currentRoute.value?.query?.variant
    selectedVariant.value = getSelectableVariant(
      urlVariantQuery,
      props.product.variants,
    )

    trackProductView(
      props.product,
      window.klaviyo,
      $csGtm,
      $config.public.BASE_URL,
    )
  })

  // Subtitle
  const productSubtitle = computed(() => {
    const result = []

    const oilString = props.product.essentialOil
      ? "Essential Oil"
      : props.product.fragrance
        ? "Fragrance Oil"
        : undefined

    if (oilString) {
      result.push(oilString)
    }

    if (props.product.subtitle) {
      result.push(props.product.subtitle)
    }

    return result.join(" - ")
  })

  // Prompt the add to cart form to show the fragrance selection button when selected variant has kitconfiguratiom but no kit items

  const showFragranceChooseButton = computed(
    () =>
      (selectedVariant.value?.kitConfiguration &&
        (selectedVariant.value?.kitItems?.length ?? 0) <= 0) ||
      false,
  )

  // Call addKitItemsToVariant with currently selected variant and add loading states around it

  const getFragrances = async (
    variant: SolidusProductVariant = undefined as any,
  ) => {
    if (!productVariantFragranceChoice.value) {
      console.error("productVariantFragranceChoice not found")
      return
    }
    if (!variant) {
      console.error("variant for fragrance enhancement not found")
      return
    }
    try {
      loading.value = true
      const updatedVariant = await addKitItemsToVariant(
        variant,
        productVariantFragranceChoice.value,
      )
      selectedVariant.value = updatedVariant
      tmpKitSelectionStore.value[variant.id] = updatedVariant
    } catch (error) {
      console.error("Error in getting fragrances", error)
    } finally {
      loading.value = false
    }
  }
</script>

<style lang="scss">
  .product-detail-page {
    .product-detail-content {
      display: flex;
      justify-content: space-between;
      @supports (display: grid) {
        display: grid;
        grid-gap: $gutter;
        grid-template-columns: 0.7fr 1fr 200px;
        @include viewport("m", "sm") {
          grid-template-columns: 0.7fr 1fr;
        }
        @include viewport("mini") {
          grid-template-columns: 1fr;
        }
      }
    }

    .product-notice {
      flex-direction: column;
      padding: $base-spacing * 6;
      margin-top: 0;
      margin-bottom: $space-m;
      h3 {
        @apply h3;
      }

      h4 {
        @apply h4;
      }
      @include bookend();
    }

    .product-name_wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: $space-s;
      @include viewport("mini") {
        display: none;
      }
    }

    .product-headline {
      margin: 0;
      @include viewport("mini") {
        margin-top: 0;
      }
    }

    .product-sub-headline {
      color: $sand-text;
      font-weight: bold;
    }

    .product-mobile-detail {
      flex-flow: column wrap;
      justify-content: space-between;
      align-items: flex-start;
      display: none;
      @include viewport("mini") {
        display: flex;
      }
    }

    .product-images {
      flex-grow: 1;
      margin-right: $base-spacing * 12;

      @supports (display: grid) {
        margin-right: 0;
        width: auto;
      }
    }
    .product-infos {
      flex-grow: 1.3;
      margin-right: $base-spacing * 12;
      @supports (display: grid) {
        margin-right: 0;
        width: auto;
      }
      @include viewport("mini") {
        margin-top: 0;
      }
    }

    .product-extra {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include viewport("sm", "m") {
        grid-column: 1/3;
      }
      @include viewport("mini") {
        grid-gap: $base-spacing * 2;
      }
    }

    .product-social-icons {
      padding: $base-spacing * 2.6 0;
      transform: translateX(-2px);
    }

    .header-reviews-wrap {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-end;
      @include viewport("sm", "m") {
        width: initial;
        align-items: center;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: center;
        grid-gap: $space-s;
      }
      @include viewport("mini") {
        align-items: center;
        width: initial;
      }
    }
    .header-reviews {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      color: $yellow-text;
      position: relative;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
      @include viewport("sm", "m") {
        padding: 0;
      }
    }
    .at-text {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      z-index: -200;
      user-select: none;
      margin: 0;
      font-size: 1px;
    }

    .header-reviews-label {
      white-space: nowrap;
      padding-left: $base-spacing * 2;
      transform: translateY(1px);
    }

    .secondary {
      border-top: 1px solid $silver-400;
      border-bottom: 1px solid $silver-400;
      background: $sand-400;
      margin-top: $gutter-double;
    }

    .secondary-main {
      display: grid;

      grid-gap: $gutter;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto;
      @include viewport("mini", "sm", "md") {
        grid-gap: $base-spacing * 6;
      }
      @include viewport("mini", "sm") {
        grid-template-columns: 1fr;
      }
    }

    .pdp-description {
      background: white;

      border-radius: $border-radius-large;
      box-shadow: $shadow;

      img {
        width: 100%;
        border-radius: $border-radius;
      }

      h3,
      h4,
      h5,
      strong {
        font-weight: bold;
      }
    }
    .pdp-information {
      grid-column: 2;
      grid-row: 1/4;
      @include viewport("mini", "sm") {
        grid-column: inherit;
        grid-row: inherit;
      }
    }

    .product-curalate-wrap {
      max-width: 822px;
      overflow: hidden;
      @include card;
      margin-bottom: 0;

      @include viewport("mini", "sm") {
        max-width: calc(100vw);
      }
    }

    .related-products {
      display: flex;
      flex-flow: row wrap;
      grid-gap: $space-s;
      margin-bottom: $space-m;
    }
    .related-products-headline {
      width: 100%;
      flex-shrink: 0;
    }
    .product-curalate {
      @include viewport("mini", "sm") {
        padding: $space-s;
      }
    }

    .video-thumb {
      width: 100%;
    }
    .text {
      margin: $gutter 0;
      color: $onyx;
      padding: 0 $gutter;
      @apply text-md leading-normal;
      @include bookend();
      strong,
      b {
        font-weight: bold;
        color: $onyx;
      }
      ul,
      ol {
        padding-left: 1.25em;
      }

      @include viewport("mini") {
        @apply text-sm leading-normal;
      }
    }
    .reviews {
      @include card;
    }
    .reviews-headline {
      margin: 0;
    }

    .reviews-intro {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $space-s;
    }

    .document-links {
      display: grid;
      align-items: flex-start;
      @include viewport("mini", "sm") {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }

  .product-reviews-empty-state {
    text-align: left;
    color: $gray-text;
    padding-bottom: $space-s;
  }

  .cs-icon {
    display: inline-block;
    width: $base-spacing * 8;
    height: $base-spacing * 8;
    svg {
      height: 100%;
      width: 100%;
    }
  }

  .product-replaced-notice {
    margin-bottom: $space-m;
  }
</style>
