<template>
  <div>
    <div class="stage-wrap" :class="images && 'has-thumbs'">
      <div class="product-image-labels">
        <ProductLabel
          v-for="label in labels"
          :key="label.name"
          size="large"
          :type="label.slug"
          :text="label.name"
        />
      </div>
      <ProductPicture
        v-for="(image, index) in images"
        v-show="selectedImage === index"
        :key="image.id"
        :image="image"
        class="stage"
        :class="isBlendingElement && index === 0 && 'stage-circle'"
        role="none"
        :loading="index === 0 ? 'eager' : 'lazy'"
        :data-index="index"
        :sizes="sizesString"
        :discontinued="discontinued"
        :discontinued-text="true"
        :has-replacement="hasReplacement"
      />
    </div>
    <template v-if="images.length > 1">
      <button
        v-for="(image, index) in images"
        :key="index"
        :class="['thumb', index == selectedImage && 'thumb-selected']"
        class="focus:outline-blue-700 focus:outline-offset-4 focus:shadow-none"
        @click="selectedImage = index"
      >
        <ProductPicture
          loading="lazy"
          role="none"
          :image="image"
          sizes="50px"
        />
      </button>
    </template>
  </div>
</template>

<script setup>
  import createSizesString from "~/lib/createSizesString"

  defineProps({
    images: {
      type: Array,
      required: true,
    },
    discontinued: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    hasReplacement: {
      type: Boolean,
      default: false,
    },
    isBlendingElement: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: String,
      default: "eager",
    },
  })

  const sizesString = createSizesString({
    max: {
      size: 419,
    },
    md: {
      modifier: 2.9,
      spacing: 21.33,
    },
    sm: {
      modifier: 2.42,
      spacing: 16,
    },
    mini: {
      modifier: 1,
    },
  })

  const selectedImage = ref(0)
</script>

<style lang="scss" scoped>
  .stage-wrap {
    position: relative;
    aspect-ratio: 1/1;

    &.has-thumbs {
      margin-bottom: $base-spacing * 2;
    }
  }

  .product-image-labels {
    display: flex;
    flex-direction: column;
    gap: $base-spacing;
    align-items: flex-end;
    position: absolute;
    left: $base-spacing * 6;
    right: $base-spacing * 6;
    bottom: $base-spacing * 6;
    z-index: 5;
  }
  .stage {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    border-radius: $border-radius-large;
    overflow: hidden;
    box-shadow: $shadow;
    &-circle {
      border-radius: 50%;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }
  .thumb {
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    box-shadow: $shadow;
    margin-right: $base-spacing * 2;
    border-radius: $border-radius;
    padding: 0;
    border: 0;
    width: 50px;
    height: 50px;

    &.thumb-selected,
    &:hover {
      box-shadow: 0 0 0 2px $navy-500;
    }
  }
</style>
