<template>
  <div class="card">
    <Headline :level="2" :size="5">Details</Headline>
    <PropertyTable :properties="essentialOilDetails" />
  </div>
  <div class="card">
    <Headline :level="2" :size="5">Recommended Applications</Headline>
    <PropertyTable :properties="essentialOilRecommendedApplications" />
    <Hint>
      Usage amounts are recommendations only. Individual usage amounts can vary
      based on your desired essential oil strength, wax type, soap base, or
      lotion base. See IFRA certificate for max usage levels. Bottles filled by
      weight, levels may vary.
    </Hint>
    <Hint v-if="essentialOilData.contains_prop_65_ingredients">
      **Warning: {{ essentialOilData.prop_65_warning }}
    </Hint>
  </div>
  <div v-if="soapPerformance" class="card">
    <Headline :size="5">Cold Process Soap Performance</Headline>
    <SoapPerformance
      :acceleration="soapPerformance.acceleration"
      :discoloration="soapPerformance.discoloration"
      :separation="soapPerformance.separation"
    />
    <Hint
      link-label="Read more about our process"
      link-href="/fragrance-oil/process-for-cp-soap-fragrance-testing/"
    >
      {{ soapPerformance.sanitized_body }}
    </Hint>
  </div>
</template>

<script>
  import Headline from "~/components/Headline"
  import Hint from "~/components/Hint"
  import PropertyTable from "~/components/PropertyTable"
  import SoapPerformance from "~/components/SoapPerformance"

  export default {
    components: {
      Headline,
      PropertyTable,
      Hint,
      SoapPerformance,
    },
    props: {
      essentialOilData: { type: Object, required: true },
    },
    data() {
      return {
        essentialOilDetails: this.essentialOilData?.infos,
        essentialOilRecommendedApplications:
          this.essentialOilData?.recommendedApplications,
        soapPerformance: this.essentialOilData?.coldProcessDisclaimer,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .card {
    @include card;
  }
</style>
