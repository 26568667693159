<template>
  <div class="wrap">
    <DetailRow>
      <template #name> Acceleration </template>
      <template #extra>
        <span class="label">{{ acceleration }}</span>
      </template>
    </DetailRow>
    <DetailRow>
      <template #name>
        <span>Discoloration</span>
      </template>
      <template #extra>
        <span class="label">{{ discoloration }}</span>
      </template>
    </DetailRow>
    <DetailRow>
      <template #name>
        <span>Separation</span>
      </template>
      <template #extra>
        <span class="label">{{ separation }}</span>
      </template>
    </DetailRow>
  </div>
</template>

<script>
  import DetailRow from "../DetailRow"

  export default {
    components: { DetailRow },
    props: {
      acceleration: {
        type: String,
        required: true,
      },
      discoloration: {
        type: String,
        required: true,
      },
      separation: {
        type: String,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .wrap {
    margin-bottom: $base-spacing * 4;
  }
  .label {
    font-weight: bold;
    color: $gray-text;
  }
</style>
