<template>
  <label
    class="relative gap-x-2 min-h-6 px-3 py-2 mb-1 grid"
    :class="[
      checked ? 'cs-selectable-active' : 'cs-selectable',
      variant.kitItems?.length
        ? 'grid-cols-[auto,1fr,auto]'
        : ' grid-cols-[auto,1fr]',
    ]"
  >
    <!-- Radio Circle/Check Svg  -->
    <div class="relative h-5.5 inline-flex items-center">
      <input
        type="radio"
        :checked="checked"
        :disabled="itemDisabled"
        class="absolute inset-0 opacity-0"
        name="variants"
        @change="onVariantSelect(variant)"
      />
      <div
        v-if="!checked"
        class="inline-flex w-5 h-5 items-center justify-center"
      >
        <div
          :class="[
            itemDisabled && 'opacity-50',
            checked && activeVolumePriceIds
              ? 'border-blue-shape'
              : 'border-gray-800',
          ]"
          class="inline-block w-4 h-4 border-solid border-2 rounded-full box-border"
        />
      </div>
      <SvgIcon v-else icon="form-check" class="w-5 h-5 text-blue-800" />
    </div>

    <div class="info">
      <!-- Variant Content -->
      <div
        class="grid grid-cols-[auto,1fr,auto,auto] gap-x-3"
        :class="itemDisabled && 'opacity-35'"
      >
        <!-- Variant Name -->

        <div class="font-semibold">
          {{ variant.optionsText }}
        </div>

        <!-- Info -->
        <div
          class="inline-flex items-baseline leading-1 row-start-2 col-start-1 col-end-3 sm:col-start-auto sm:col-end-auto sm:row-start-auto"
        >
          <!-- Out of Stock & Stock Notice -->
          <template v-if="!variant.inStock && variant.backorderable">
            Backorder: Ships in {{ variant.estimatedDaysToFillBackorder }} days.
          </template>
          <template v-else>
            <div
              v-if="variant.stockNotice || !variant.inStock"
              class="col-start-2"
            >
              <span
                v-if="variant.stockNotice?.status && !variant.backorderable"
                type="shipping"
              >
                <strong>{{ stockNoticeTrimmed }}</strong>
                <span v-if="variant.stockNotice.expectation">
                  {{ variant.stockNotice.expectation }}
                </span>
                <button
                  v-if="variant.stockNotice?.status === 'Out of stock.'"
                  class="font-semibold underline bg-transparent border-none font-sans p-0"
                  @click="$emit('notifyOfStockClick')"
                >
                  Notify me
                </button>
              </span>
            </div>
          </template>
        </div>

        <template v-if="!hidePrice">
          <!-- Price per unit of Sale-->
          <span class="text-right transition-transform origin-right">
            ({{ variant.displayPricePerUnitOfSale
            }}<template v-if="variant.unitOfSale"
              >/{{ variant.unitOfSale }} </template
            >)
          </span>

          <!-- Variant Price -->
          <!-- Sale case -->
          <span
            v-if="variant.oneOzSaleDisplayPrice"
            class="text-right font-semibold"
          >
            <span class="">{{ variant.oneOzSaleDisplayPrice }}</span>
            <s>
              {{ variant.displayPrice }}
            </s>
          </span>

          <!-- Default Case -->
          <span
            v-else
            class="text-right transition-transform origin-right"
            :class="[
              checked && activeVolumePriceIds.length > 0
                ? 'font-normal'
                : 'font-semibold',
            ]"
          >
            {{ variant.displayPrice }}
          </span>
        </template>
      </div>

      <!-- Volume Prices -->
      <!-- December 2023 Introduced permanent 5lb Jug Discount  -->
      <template
        v-if="
          isFragrance &&
          !productDiscontinued &&
          variant.optionsText === '5 lb Jug'
        "
        >10% any 6+, 15% off any 12+
      </template>
      <div
        v-for="volumePrice in variant.volumePrices"
        :key="volumePrice.name + 'name'"
        class="flex gap-3"
      >
        <span class="flex-1">
          {{ volumePrice.name }}
        </span>
        <span class="text-right transition-transform origin-right">
          ( {{ volumePrice.displayPricePerUnitOfSale }}
          <template v-if="variant.unitOfSale">
            /{{ variant.unitOfSale }} </template
          >)
        </span>

        <span
          class="text-right transition-transform origin-right"
          :class="[
            checked &&
              activeVolumePriceIds.includes(volumePrice.id) &&
              'font-semibold',
          ]"
        >
          {{ volumePrice.displayPrice }}
        </span>
      </div>
      <div v-if="'kitItems' in variant && variant.kitItems" class="row-start-2">
        {{ createKitVariantString(variant.kitItems) }}
      </div>
    </div>

    <button
      v-if="variant.kitItems?.length"
      class="bg-transparent border-none font-sans font-semibold self-start p-1 text-blue-text"
      @click="$emit('changeButton', variant.kitItems)"
    >
      edit
    </button>
  </label>
</template>

<script setup lang="ts">
  import { determineActiveVolumePrices } from "./determineActiveVolumePrices.js"
  import { createKitVariantString } from "~/utils/createKitVariantString"

  const emit = defineEmits<{
    productVariantChange: [variantSelection: ProductVariantWithKitItems]
    notifyOfStockClick: []
    changeButton: [VariantSelection: VariantSelection[]]
  }>()

  const props = withDefaults(
    defineProps<{
      hidePrice: boolean
      hasVariants?: boolean
      variant: ProductVariantWithKitItems
      isFragrance?: boolean
      checked: boolean
      quantity: number
      productDiscontinued?: boolean
    }>(),
    {
      default: false,
      saleLabel: "",
      productDiscontinued: false,
      isFragrance: false,
      hasVariants: true,
    },
  )

  const itemDisabled = computed(
    () => !props.variant.inStock && !props.variant.backorderable,
  )

  const stockNoticeTrimmed = computed(() => {
    const { status } = props.variant.stockNotice

    if (status) {
      const endsInPeriod = status.lastIndexOf(".") === status.length - 1
      return endsInPeriod ? status.slice(0, -1) : status
    }

    return null
  })

  // This is used to determine if the selected quantity does cause a volume price change of this variant
  const activeVolumePriceIds = computed(() => {
    return determineActiveVolumePrices(
      props.quantity,
      props.variant.volumePrices,
    )
  })

  const onVariantSelect = (variant: ProductVariantWithKitItems) => {
    emit("productVariantChange", variant)
  }
</script>
