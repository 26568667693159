import type { LocationQueryValue } from "vue-router"

export const convertOptionsTextToQuery = (optionsText: string) => {
  return optionsText.toLowerCase().replaceAll(" ", "-")
}

const isInStock = (variant: ProductVariantWithKitItems) => {
  return variant.inStock || variant.backorderable
}

const getFirstInStock = (variants: ProductVariantWithKitItems[]) =>
  variants.find((variant) => isInStock(variant))

const variantFromUrl = (
  query: string,
  variants: ProductVariantWithKitItems[],
): ProductVariantWithKitItems | undefined => {
  const variantMatch = variants.find((variant) => {
    return convertOptionsTextToQuery(variant.optionsText) === query
  })

  if (variantMatch) {
    return isInStock(variantMatch) ? variantMatch : undefined
  }
}

export const getSelectableVariant = (
  query: LocationQueryValue | LocationQueryValue[],
  variants: ProductVariantWithKitItems[],
): ProductVariantWithKitItems | undefined => {
  // With url param
  const joinedQuery = Array.isArray(query) ? query.join("") : query

  const urlVariant = joinedQuery
    ? variantFromUrl(joinedQuery, variants)
    : undefined

  return urlVariant || getFirstInStock(variants) || undefined
}
