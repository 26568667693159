import type { GtmSupport } from "@gtm-support/core"

const gtmProductView = (product: SolidusProduct, gtm: GtmSupport) => {
  gtm.trackEvent({
    event: "viewProduct",
    ecommerce: {
      detail: {
        products: [
          {
            id: product.id,
            name: product.name,
            category: product.gaCategory,
          },
        ],
      },
    },
    skus_viewed: product.variants.map((variant) => variant.sku),
    product_viewed: product.name,
  })
}

const klaviyoProductView = (
  product: SolidusProduct,
  klaviyo: any,
  productURL: string,
) => {
  if (typeof klaviyo !== "undefined") {
    klaviyo.push(
      [
        "track",
        "Viewed Product",
        {
          ProductID: product.id,
          ProductName: product.name,
          Categories: product.gaCategory,
          ImageURL: product.imageUrl,
          URL: productURL,
        },
      ],
      [
        "trackViewedItem",
        {
          Title: product.name,
          ItemId: product.id,
          Categories: product.gaCategory,
          ImageUrl: product.imageUrl,
          URL: productURL,
        },
      ],
    )
  }
}

const trackProductView = (
  product: SolidusProduct,
  klaviyo: any,
  gtm: GtmSupport | undefined,
  baseUrl: string,
) => {
  const productURL = `${baseUrl}${product.urlPath}`
  klaviyoProductView(product, klaviyo, productURL)
  if (gtm) {
    gtmProductView(product, gtm)
  }
}

export default trackProductView
