const isInRange = (range: VariantVolumePrice["range"], quantity: number) => {
  const hitsMin = quantity >= range[0]
  const doesnotExceedMax = range[1] === null ? true : quantity <= range[1]
  return hitsMin && doesnotExceedMax
}

export const determineActiveVolumePrices = (
  quantity: number,
  volumePrices: VariantVolumePrice[],
): VariantVolumePrice["id"][] => {
  return volumePrices
    .filter((volumePrice) => {
      return isInRange(volumePrice.range, quantity) ? volumePrice.id : null
    })
    .map((volumePrice) => volumePrice.id)
}
