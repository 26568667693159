//  Because we do not display variant images from solidus but do make use of the variant images in some product we need to collection all the images from the variants and transform them into a digestable array for the Product Images component.
//  There is also the case that images are attached to multiple variants and we need to make sure we do not duplicate images in the array.

export const variantImageMap = (
  variants: SolidusProductVariant[],
): ProductPageImage[] => {
  return variants.reduce(
    (acc, variant) => {
      variant.images.forEach((image) => {
        if (!acc.set.has(image.largeUrl)) {
          acc.set.add(image.largeUrl)
          acc.images.push({
            src: image.largeUrl,
            alt: image.alt,
            srcset: image.srcset,
          })
        }
      })
      return acc
    },
    { set: new Set<string>(), images: [] as ProductPageImage[] },
  ).images
}
