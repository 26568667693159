<template>
  <CsDialog
    ref="ProductStockNotificationDialog"
    title="Notify Me"
    cancel-label="Close"
    confirm-label="Notify Me"
    @confirm="submit"
    @cancel="close"
    @close="close"
  >
    <form @submit.prevent="submit">
      <p class="font-semibold">Notify me when products are in stock</p>
      <Alert v-for="alert in alerts" :key="alert.message" :type="alert.type">
        {{ alert.message }}
      </Alert>
      <template v-if="errors.length">
        <Alert v-for="(error, index) in errors" :key="index">
          {{ error }}
        </Alert>
      </template>
      <div class="mb-4">
        <FormInput
          v-model="email"
          placeholder="example@example.com"
          autocomplete="email"
          type="email"
          label="Email"
          :required="true"
        />
      </div>
      <div class="mb-4">
        <FormSelect
          label="Stock Location"
          :required="true"
          :options="locations"
          :model-value="selectedLocation"
          @update:model-value="onSelectUpdate"
        />
      </div>
    </form>
  </CsDialog>
</template>

<script setup lang="ts">
  import { deserialize } from "@alchemy_cms/json_api"
  import CsDialog from "../CsDialog.vue"

  const props = defineProps({
    productUrl: {
      type: String,
      default: "",
    },
    open: {
      type: Boolean,
      default: false,
    },
  })

  const { api } = useApi()
  const { alerts, handleError } = useErrorHandling()
  const ProductStockNotificationDialog: Ref<InstanceType<
    typeof CsDialog
  > | null> = ref(null)

  const emit = defineEmits(["close-notification", "submit-success"])
  const errors: Ref<string[]> = ref([])
  const selectedLocation = ref("")
  const email = ref("")

  const close = () => {
    emit("close-notification")
    ProductStockNotificationDialog.value?.close()
  }

  const onSelectUpdate = (event: string) => {
    selectedLocation.value = event
  }

  watch(
    () => props.open,
    (newVal) => {
      if (newVal) {
        ProductStockNotificationDialog.value?.openModal()
      }
    },
  )

  const { data: locations } = await useAsyncData(
    () => api("/jsonapi/stock_locations"),
    {
      transform(data) {
        const options = deserialize(data).map((e: StockLocation) => {
          return { value: e.name, label: e.name }
        })
        return options
      },
    },
  )

  const submit = () => {
    errors.value = []
    if (validForm()) {
      api("/cs/api/product_notifications", {
        method: "POST",
        body: {
          email: email.value,
          stock_location: selectedLocation.value,
          slug: props.productUrl,
        },
      })
        .then(() => {
          emit("close-notification")
          ProductStockNotificationDialog.value?.close()
          emit("submit-success")
        })
        .catch((e) => {
          handleError(e)
        })
    }
  }

  const validForm = () => {
    if (!email.value) {
      errors.value.push("Please input an email address")
    }
    if (!selectedLocation.value) {
      errors.value.push("Please select a stock location")
    }
    return !!selectedLocation.value && !!email.value
  }
</script>
