<template>
  <div class="product-cart-form">
    <component
      :is="!hasVariants ? 'div' : 'fieldset'"
      class="flex flex-col border-none p-0 m-0 mb-4"
    >
      <legend v-if="hasVariants" class="sr-only">Choose a Variant</legend>

      <div
        v-if="allVariantPricesAreTheSameAndNoVolumePrices"
        class="mb-2 text-lg font-semibold"
      >
        {{ props.variants[0]?.displayPrice }}
      </div>

      <ProductVariant
        v-for="variant in variants"
        :key="variant.id"
        :is-fragrance="isFragrance"
        :quantity="quantity || 0"
        :variant="variant"
        :checked="variant.id === modelValue?.id"
        :has-variants="hasVariants"
        :hide-price="allVariantPricesAreTheSameAndNoVolumePrices"
        :product-discontinued="isDiscontinued"
        @notifyOfStockClick="showNotificationModal"
        @change-button="$emit('getFragrances', $event)"
        @productVariantChange="onVariantSelected"
      />
    </component>

    <Alert
      v-if="canShowStockNotifyConfirmation"
      class="out-of-stock-notice-alert"
      size="small"
      type="success"
    >
      <template #icon>
        <SvgIcon icon="info" />
      </template>
      You will be notified when this item is back in stock!
    </Alert>

    <div v-if="alerts.length" class="items">
      <Alert v-for="alert in alerts" :key="alert.message" :type="alert.type">
        <template #icon>
          <SvgIcon icon="x-circle" />
        </template>
        {{ alert.message }}
      </Alert>
    </div>

    <div v-if="showFragranceChooseButton">
      <Button
        :loading="loading"
        color="green"
        class="w-full"
        @click="$emit('getFragrances')"
      >
        Choose your Fragrances
      </Button>
    </div>

    <form v-else class="qty" @submit.prevent="submit">
      <label class="qty-wrap">
        <span class="qty-label">Qty.</span>
        <input
          :disabled="disabled"
          v-model="quantity"
          placeholder="1"
          min="1"
          class="qty-input"
          :class="disabled && 'disabled'"
          type="number"
          max="5000"
        />
      </label>

      <Button
        :disabled="!modelValue || disabled"
        :loading="loading"
        class="add-to-cart-button"
      >
        Add to Cart
      </Button>
    </form>

    <ProductStockNotification
      :open="notificationModalOpen"
      :product-url="productUrl"
      @close-notification="hideNotificationModal"
      @submit-success="displayAlert"
    />
  </div>
</template>

<script setup lang="ts">
  const { alerts } = useErrorHandling()

  const emit = defineEmits<{
    "update:modelValue": [variant: ProductVariantWithKitItems]
    addToCart: [quantity: number]
    getFragrances: [variants?: VariantSelection[]]
  }>()

  const props = withDefaults(
    defineProps<{
      disabled?: boolean
      loading?: boolean
      modelValue: ProductVariantWithKitItems | undefined
      isFragrance?: boolean
      productUrl: string
      showFragranceChooseButton: boolean
      isDiscontinued?: boolean
      variants: SolidusProductVariant[]
    }>(),
    {
      showFragranceChooseButton: false,
      loading: false,
      disabled: false,
      isFragrance: false,
      isDiscontinued: false,
    },
  )

  const quantity: Ref<number | undefined> = ref()
  const notificationModalOpen = ref(false)
  const canShowStockNotifyConfirmation = ref(false)

  const hasVariants = computed(() => {
    return props.variants.length > 1
  })

  const displayAlert = () => {
    canShowStockNotifyConfirmation.value = true
  }

  const showNotificationModal = () => {
    notificationModalOpen.value = true
  }

  const hideNotificationModal = () => {
    notificationModalOpen.value = false
  }

  const onVariantSelected = (variant: ProductVariantWithKitItems) => {
    emit("update:modelValue", variant)
  }

  const allVariantPricesAreTheSameAndNoVolumePrices = computed(() => {
    return (
      props.variants.every(
        (variant) => variant.displayPrice === props.variants[0].displayPrice,
      ) && !props.variants[0]?.volumePrices.length
    )
  })

  const submit = () => {
    emit("addToCart", quantity.value || 1)
  }
</script>

<style lang="scss" scoped>
  .qty {
    margin-top: $base-spacing * 4;
    padding: 0;
    display: flex;
    grid-gap: $base-spacing * 2;
  }

  .qty-wrap {
    width: 35%;
    position: relative;
    grid-gap: $base-spacing * 2;
  }

  .qty-label {
    @apply text-md leading-normal;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: $base-spacing * 6;
    color: $gray-text;
  }

  .qty-input {
    @extend %input-style;

    padding: 0;
    padding-left: $base-spacing * 16;
    margin: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    &.disabled {
      user-select: none;
    }
  }

  .add-to-cart-button {
    flex: 1;
  }
  .alert {
    margin: $base-spacing * 4;
  }
  .out-of-stock-notice-alert {
    margin-left: 0;
    margin-right: 0;
    justify-content: flex-start;
  }
  .cart-form-warning {
    @apply text-sm leading-normal;
    padding-top: $space-s;
    color: $yellow-text;
  }
</style>
